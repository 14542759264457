<template>
  <div>
    <div class="title_area">
      <h2>Add Details</h2>
      <h3>Add details to generate certificate.</h3>
    </div>

    <form name="project-form" class="form_area" @submit.prevent="submitForm">

      <div class="input_area">
        <label>Project Name</label>
        <input type="text" required v-model="name" id="name" v-on:click="input_click" placeholder="Enter project name">
      </div>

      <div class="input_area" style="margin-right: 0;">
        <label>Housebuilder</label>
        <input type="text" style="width: 96%;" required v-model="housebuilder" id="housebuilder" v-on:click="input_click"
          placeholder="Enter housebuilder">
      </div>

      <div class="input_area">
        <label>Sun.Day rating</label>
        <input type="text" required v-model="sunday_rating" id="sunday_rating" v-on:click="input_click"
          placeholder="Enter Sun.Day rating">
      </div>

      <div class="input_area_button">
        <button class="op0">Back</button>
        <button class="save_button first_colored" type="submit">Publish certificate</button>
      </div>
    </form>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      name: '',
      housebuilder: '',
      sunday_rating: '',
    };
  },
  computed: {
    ...mapGetters(['getProjectId'])
  },
  methods: {
    submitForm() {
      if (this.getProjectId != null) {
        this.$emit('change-tab', 2);
        //alert('güncellenecek');
       //alert(this.name)
        // update sorgusu

        const url = 'https://rest.wedesignwecode.com/?type=update_project_name&project_id=' +this.getProjectId+ '&name=' + this.name+ '&housebuilder=' + this.housebuilder + '&sunday_rating=' + this.sunday_rating;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            console.log(data)
            if (data.response == "success") {
              this.$emit('change-tab', 2);
              document.getElementById("tab_1").classList.add('new-class');
            } else {
              this.addErrorStyle();
            }
          })
          .catch(error => {
            console.log(error);
          });


      } else {
        const url = 'https://rest.wedesignwecode.com/?type=create_project&client_id=' + localStorage.getItem('client_email') + '&name=' + this.name+ '&housebuilder=' + this.housebuilder + '&sunday_rating=' + this.sunday_rating;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (data.response == "success") {
              this.createSession(data.project_id);
              this.$emit('change-tab', 2);
              document.getElementById("tab_1").classList.add('new-class');
            } else {
              this.addErrorStyle();
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    createSession(pid) {
      this.$store.commit("setProjectId", pid);
    },
    addErrorStyle() {
      const nameInput = document.getElementById('name');
      nameInput.classList.add('error-input');
    },
    input_click() {
      const nameInput = document.getElementById('name');
      nameInput.classList.remove("error-input");
    }
  },
  mounted() {
    if (this.$store.state.wizard.name != "") {
      this.name = this.$store.state.wizard.name;
    }
    if (this.$store.state.wizard.housebuilder != "") {
      this.housebuilder = this.$store.state.wizard.housebuilder;
    }
    if (this.$store.state.wizard.sunday_rating != "") {
      this.sunday_rating = this.$store.state.wizard.sunday_rating;
    }
  }
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,700;1,400&family=Work+Sans:wght@400;700&display=swap);

.save_button {
  display: inline-block;
  background: #27303D;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #27303D;
  float: right;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
}

form {
  display: inline-block;
  width: 100%;
}

.input_area {
  display: flex;
  width: 100%;
  max-width: 48%;
  float: left;
  flex-direction: column;
  margin-right: 24px;
}

.input_area input {
  width: calc(100% - 32px );
  display: inline-block;
  background-color: #fff;
  font-family: Work Sans;
  border: none;
  font-family: 'Inter';
  outline: none;
  color: #555;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #27303D;
  padding: 14px 16px;
}

.input_area label {
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #27303D;
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
}

.title_area {
  display: inline-block;
  width: 100%;
  margin-bottom: 65px;
}

.title_area h2 {
  margin: 0;
  font-family: Libre Baskerville;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.title_area h3 {
  margin: 0;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
</style>