<template>
    <div class="room">
        <h3 v-html="room.name"></h3>
        <div @click="onFile" :class="['upload_box', {
            'no-border': image != null
        }]">
            <div class="upload_box_area">
                <span v-if="getImageUrl == null">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon">
                            <path id="Vector" d="M10 4.16675V15.8334" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path id="Vector_2" d="M4.16699 10H15.8337" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                    </svg>
                    Upload Image
                </span>
                <img v-else :src="getImageUrl" class="blob_img" />
            </div>

            <p>
                <input ref="fileRef" class="file_area" type="file" @change="handleChangeFile">
            </p>
        </div>
        <button class="delete_img" type="button" v-if="getImageUrl != null" @click="image = null"><svg width="20"
                height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icon">
                    <rect y="0.480469" width="20" height="20" rx="10" fill="#27303D" />
                    <path id="Vector" d="M2.5 5.48047H4.16667H17.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path id="Vector_2"
                        d="M15.8337 5.48043V17.1471C15.8337 17.5891 15.6581 18.013 15.3455 18.3256C15.0329 18.6382 14.609 18.8138 14.167 18.8138H5.83366C5.39163 18.8138 4.96771 18.6382 4.65515 18.3256C4.34259 18.013 4.16699 17.5891 4.16699 17.1471V5.48043M6.66699 5.48043V3.81376C6.66699 3.37173 6.84259 2.94781 7.15515 2.63525C7.46771 2.32269 7.89163 2.14709 8.33366 2.14709H11.667C12.109 2.14709 12.5329 2.32269 12.8455 2.63525C13.1581 2.94781 13.3337 3.37173 13.3337 3.81376V5.48043"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3" d="M8.33301 9.64709V14.6471" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_4" d="M11.667 9.64709V14.6471" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </g>
            </svg></button>
    </div>
</template>
<script>
export default {
    data: () => ({
        image: null,
    }),
    methods: {
        handleChangeFile($event) {
            const files = $event.target.files;
            if (files.length) {
                this.image = files[0];
                this.$emit('newFile', {
                    roomName: this.room.name,
                    file: files[0]
                });
            }
        },
        onFile() {
            console.log(this.$refs);
            this.$refs.fileRef.click();
        }
    },
    computed: {
        getImageUrl() {
            if (this.image != null) {
                return URL.createObjectURL(this.image);
            }
            return null;
        }
    },
    props: {
        room: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.delete_img {
    position: absolute;
    top: 70px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: #27303D;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.delete_img svg {}

.blob_img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    float: left;
}

.upload_box {
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #27303D;
}

.upload_box .file_area {
    display: none;
}

.upload_box_area span {
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    color: #27303D;
    cursor: pointer;
}

.upload_box_area svg {
    float: left;
}

.room_area input[type="file"] {
    display: block;
}

.room {
    float: left;
    position: relative;
    width:300px;
}

.room_name {
    font-weight: 500;
}
.no-border {
    border: none !important;
}
</style>