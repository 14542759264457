export default {
    state: {
        status: null,
        housebuilder: "",
        sunday_rating: "",
        name: "",
        projectId: null,
        floors: []
    },
    getters: {
        getProjectId(state) {
            return state.projectId;
        }
    },
    mutations: {
        setName(state, payload) {
            state.name = payload;
        },
        setHousebuilder(state, payload) {
            state.housebuilder = payload;
        },
        setSunday_rating(state, payload) {
            state.sunday_rating = payload;
        },
        setStatus(state, payload) {
            state.status = payload;
        },
        setProjectId(state, payload) {
            state.projectId = payload;
        },
        setFloors(state, payload) {
            state.floors = payload;
        }
    },
    actions: {
        reset({ commit }) {
            commit('setName', "")
            commit('setStatus', null)
            commit('setHousebuilder', "")
            commit('setSunday_rating', "")
            commit('setProjectId', null)
            commit('setFloors', [])
        }
    }
}