<template>
    <div class="floor">
        <h2 v-html="floor.title" class="title"></h2>
        <div class="rooms">
            <RoomImage v-for="(room, index) in floor.rooms" :key="index" :room="room" @newFile="newFile" />
        </div>
    </div>
</template>
<script>
import RoomImage from "./RoomImage.vue";
export default {
    props: {
        floor: {
            type: Object,
            required: true
        }
    },
    methods: {
        newFile(payload) {
            this.$emit('newFile', {
                ...payload,
                floorName: this.floor.title
            });
        }
    },
    components: {
        RoomImage
    }
}
</script>

<style scoped>
.floor {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #BEB694;
    padding-bottom: 50px;
    margin-bottom: 35px;
}

.rooms {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
}
</style>