<template>
    <div class="room_item" v-if="showConditionalContent">

        <div class="modal_area" v-if="modalRoom">
            <div class="modal_box">
                <button type="button" @click="closeModal()" class="modal_close_arow"><svg width="14" height="15"
                        viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon">
                            <path id="Shape_2119" d="M1 1.48047L13 13.4805" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" />
                            <path id="Shape_2120" d="M13 1.48047L1 13.4805" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" />
                        </g>
                    </svg></button>
                <div class="title">
                    <font class="title">Delete Room?</font>
                    <p>Are you sure you want to delete this room?</p>
                </div>
                <div class="button">
                    <button type="button" @click="closeModal()" class="back_button">Cancel</button>
                    <button type="button" @click="onDeleteRoom(roomName)" class="save_button">Delete Room</button>
                </div>
            </div>
        </div>

        <div class="modal_area" v-if="modal">
            <div class="modal_box">
                <button type="button" @click="closeModal()" class="modal_close_arow"><svg width="14" height="15"
                        viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icon">
                            <path id="Shape_2119" d="M1 1.48047L13 13.4805" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" />
                            <path id="Shape_2120" d="M13 1.48047L1 13.4805" stroke="#27303D" stroke-width="1.5"
                                stroke-linecap="round" />
                        </g>
                    </svg></button>
                <div class="title">
                    <font class="title">Delete Floor?</font>
                    <p>Are you sure you want to delete this floor?</p>
                </div>
                <div class="button">
                    <button type="button" @click="closeModal()" class="back_button">Cancel</button>
                    <button type="button" @click="onDeleteFloor(delete_for_pid)" class="save_button">Delete Floor</button>
                </div>
            </div>
        </div>

        <h2>{{ floor.title }}</h2>
        <span @click="openModal(floor.id)"><svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Icon">
                    <path id="Vector" d="M2.5 5.48047H4.16667H17.5" stroke="#27303D" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_2"
                        d="M15.8332 5.48047V17.1471C15.8332 17.5892 15.6576 18.0131 15.345 18.3256C15.0325 18.6382 14.6085 18.8138 14.1665 18.8138H5.83317C5.39114 18.8138 4.96722 18.6382 4.65466 18.3256C4.3421 18.0131 4.1665 17.5892 4.1665 17.1471V5.48047M6.6665 5.48047V3.81381C6.6665 3.37178 6.8421 2.94786 7.15466 2.6353C7.46722 2.32274 7.89114 2.14714 8.33317 2.14714H11.6665C12.1085 2.14714 12.5325 2.32274 12.845 2.6353C13.1576 2.94786 13.3332 3.37178 13.3332 3.81381V5.48047"
                        stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_3" d="M8.3335 9.64714V14.6471" stroke="#27303D" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    <path id="Vector_4" d="M11.6665 9.64714V14.6471" stroke="#27303D" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </g>
            </svg></span>
        <div>
            <div class="room_item_area">

                <div v-for="(room, index) in floor.rooms" :key="index" class="room_name">
                    <font class="room_name_span">
                        {{ room.name }}</font>
                    <input type="text" v-model="room.rating" class="score_input" placeholder="Enter rating">
                    <span @click="openModalRoom(room.name)"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <g id="Icon"> <path id="Vector" d="M2.5 5.48047H4.16667H17.5" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path id="Vector_2" d="M15.8332 5.48047V17.1471C15.8332 17.5892 15.6576 18.0131 15.345 18.3256C15.0325 18.6382 14.6085 18.8138 14.1665 18.8138H5.83317C5.39114 18.8138 4.96722 18.6382 4.65466 18.3256C4.3421 18.0131 4.1665 17.5892 4.1665 17.1471V5.48047M6.6665 5.48047V3.81381C6.6665 3.37178 6.8421 2.94786 7.15466 2.6353C7.46722 2.32274 7.89114 2.14714 8.33317 2.14714H11.6665C12.1085 2.14714 12.5325 2.32274 12.845 2.6353C13.1576 2.94786 13.3332 3.37178 13.3332 3.81381V5.48047" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path id="Vector_3" d="M8.3335 9.64714V14.6471" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> <path id="Vector_4" d="M11.6665 9.64714V14.6471" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /> </g> </svg></span>
                </div>

                <div class="floor_manager" v-if="getShowCreateRoom">
                    <div class="input create_floor">
                        <input v-model="newRoom" type="text" class="floor_name" placeholder="Select room"
                            @click="toggleDropdown">
                        <ChevronDownIcon class="cursor-pointer" v-if="!newRoom.length" />
                        <PlusIcon class="cursor-pointer" @click="addRoomOption" v-else />
                    </div>
                    <div class="room dropdowns" :class="{ hidden: !dropdownVisible }">
                        <li v-for="(room, i) in roomOptions" :key="i" @click="selectRoom(room)">{{ room }}</li>
                    </div>
                </div>
                <button v-if="!showCreateRoom" class="add_room_item" @click="setShowCreateRoom(true)">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="Vector" d="M10 4.64713V16.3138" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path id="Vector_2" d="M4.1665 10.4805H15.8332" stroke="#27303D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>Add Room
                </button>
            </div>


        </div>

    </div>
</template>
  
<script>
import ChevronDownIcon from "@/components/icons/ChevronDownIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
export default {
    components: {
        ChevronDownIcon,
        PlusIcon
    },
    props: {
        floor: {
            type: Object,
            required: true
        },
        showConditionalContent: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            modal: false,
            modalRoom: false,
            dropdownVisible: false,
            rating: "",
            showCreateRoom: true,
            newRoom: "",
            roomOptions: ["Bedroom", "Kitchen", "Open plan","Bathroom","Living room","Home office","Kids room","Other"]
        };
    },
    computed: {
        getShowCreateRoom() {
            return this.showCreateRoom;
        }
    },
    methods: {
        addRoomOption() {
            this.roomOptions.unshift(this.newRoom);
            this.selectRoom(this.newRoom);
            this.newRoom= "";
        },
        setShowCreateRoom(payload) {
            this.showCreateRoom = payload;
        },
        closeModal() {
            this.modal = false;
            this.modalRoom = false;
        },
        openModal(array) {
            this.delete_for_floor = array;
            this.modal = true;
        },
        openModalRoom(room) {
            this.roomName = room;
            this.modalRoom = true;
        },
        onDeleteFloor() {
            this.modal = false;
            this.$emit('deleteFloor', this.floor.id);
        },
        onDeleteRoom(roomName) {
            const payload = {
                roomName,
                floorTitle: this.floor.title
            }
            this.modalRoom = false;
            this.$emit('deleteRoom', payload);
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        selectRoom(roomName) {
            this.dropdownVisible = false;
            this.setShowCreateRoom(false);
            this.$emit('selectRoom', {
                floor: this.floor.id,
                name: roomName,
                rating: this.rating
            });
        }
    }
};
</script>
  
<style>
.room.dropdowns.hidden {
    display: none !important;
}

.add_room_item {
    background: none;
    display: inline-block;
    list-style: none;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    margin:0;
    padding:0;
}
.add_room_item svg {
    float: left;
    margin-right: 15px;
}

.score_input {
    border-radius: 8px;
    border: 1px solid #27303D;
    background-color: #fff;
    width: 140px;
    padding: 12px 16px;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.room_item_area {
    padding-bottom: 15px;
}

.room_item_area .floor_manager {
    padding: 0;
}

.room_item font.room_name_span {
    display: inline-block;
    width: 128px;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #27303D;
    float: left;
    margin-top: 6px;
}

.rooms {}

.modal_close_arow {
    position: absolute;
    top: 40px;
    right: 25px;
    cursor: pointer;
    background: none;
    border: none;
}

.modal_area {
    background: #27303d8c;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.modal_area .modal_box {
    position: relative;
    width: 380px;
    height: 222px;
    background-color: #fff;
    border-radius: 16px;
    padding: 40px 40px;
}

.modal_area .title font.title {
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    color: #27303D;
    margin-bottom: 15px;
}

.modal_area .title p {
    font-family: Work Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #27303D;
}

.modal_area .button {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
}

.modal_area .button .back_button {
    margin-right: 15px;
}
</style>
  