<template>
  <div>
    <div class="title_area">
      <h2>Upload floor plan</h2>
      <h3>Upload images of the floor plan and sunday analyses.</h3>
    </div>

    <form @submit.prevent="submitForm" enctype="multipart/form-data">
      <div class="floor_area">
        <div class="floors">
          <div class="floor_item"  v-for="(floor, i) in wizard.floors" :key="i">
            <h1>{{ floor.title }}</h1>
            <div style="display: flex;align-items: center;gap: 25px;">
              <RoomImage :room="{ 'name': 'Before' }"
                @newFile="(payload) => onNewFile({ ...payload, floor: floor.title, type: 'before' })" />
              <RoomImage :room="{ 'name': 'After' }"
                @newFile="(payload) => onNewFile({ ...payload, floor: floor.title, type: 'after' })" />
            </div>
          </div>
        </div>
      </div>
    </form>


    <button @click="back" class="back_button" type="button">Back</button>
    <button @click="submitForm" class="save_button" type="submit">generate project</button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RoomImage from "./third/RoomImage.vue";
//import { controllers } from "chart.js";
export default {
  data: () => ({
    files: []
  }),
  computed: {
    ...mapState({
      wizard: (state) => state.wizard
    })
  },
  methods: {
    onNewFile({ file, floor, type }) {
      const findedFile = this.files.find((e) => e.floor === floor);
      if (findedFile == null) {
        if (type == 'before') {
          this.files.push({
          floor,
          before:file,
          after: null
        });
        } else {
          this.files.push({
          floor,
          before:null,
          after: file
        });
        }
      } else {
      this.files = this.files.map((e) => {
        if (e.floor === floor) {
          return {
            ...e,
            [type]: file
          }
        } 
        return e;
      });
    }
    },
    back() {
      this.$emit('changeTab', 3);
    },
    fileSelected() {
      this.showSaveButton();
    },
    showSaveButton() {
      const saveButton = document.querySelector(".hide_save_button");
      saveButton.style.display = "block";
    },
    submitForm() {
      const formData = new FormData();
      formData.append("projectId", this.wizard.projectId);
      //formData.append("files", this.files);

      for (const fileData of this.files) {
        if (fileData.before) {
          formData.append(`${fileData.floor}_before`, fileData.before);
        }
        if (fileData.after) {
          formData.append(`${fileData.floor}_after`, fileData.after);
        }
      }


      fetch("https://rest.wedesignwecode.com/?type=floor_upload", {
          method: "POST",
          body: formData
        })
        .then(response => response.json())
        .then(data => {
          if (data.response == "success") {
            this.$router.push("/project/" + this.wizard.projectId);
          }
        })
        .catch(error => {
          console.log(error);
          //alert("An error occurred during upload.");
        });

   
    }
  },
  components: {
    RoomImage
  }
};
</script>



<style scoped>
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,700;1,400&family=Work+Sans:wght@400;700&display=swap);

.back_button {
  display: inline-block;
  background: #fff;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #27303D;
  border: 1px solid #27303D;
  float: left;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 35px;
}

.save_button {
  margin-top: 35px;
  display: inline-block;
  background: #27303D;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #27303D;
  float: right;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
}

.input_area {
  max-width: 460px;
  margin-bottom: 25px;
}

.input_area input {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border: none;
  font-family: 'Inter';
  outline: none;
  color: #555;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #27303D;
  padding: 14px 16px;
}

.floor_item {
  margin-bottom:50px;
}
  
.input_area label {
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #27303D;
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
}

.title_area {
  display: inline-block;
  width: 100%;
  margin-bottom: 65px;
}

.title_area h2 {
  margin: 0;
  font-family: Libre Baskerville;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.title_area h3 {
  margin: 0;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}



.floor_area {
  width: 100%;
  display: inline-block;
}

.floors {
  width: 100%;
  display: inline-block;
}
</style>

