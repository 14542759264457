import { createRouter, createWebHistory } from 'vue-router';
import loginComponent from './components/loginComponent.vue';
import projectPage from './components/projectPage.vue';
import homeComponent from './components/homeComponent.vue';
import addProject from './components/addProject.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homeComponent
  },
  {
    path: '/add_project',
    name: 'Add Project',
    component: addProject
  },
  {
    path: '/project/:id',
    name: 'projectPage',
    component: projectPage,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: loginComponent
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
