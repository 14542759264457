<template>
    <span class="arrow">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon">
            <path id="Vector" d="M10 4.64713V16.3138" stroke="#27303D" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path id="Vector_2" d="M4.1665 10.4805H15.8332" stroke="#27303D" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
    </svg>
    </span>
</template>
<script>
export default {
    name: "PlusIcon"
}
</script>