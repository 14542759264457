<template>
    <div>
      <div class="title_area">
        <h2>Finished</h2>
        <h3>Project creation processes created successfully.</h3>
      </div> 
    </div>
  </template>

<style scoped>
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,700;1,400&family=Work+Sans:wght@400;700&display=swap);

.back_button {
  display: inline-block;
  background: #fff;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #27303D;
  border: 1px solid #27303D;
  float: left;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 35px;
}

.save_button {
  margin-top: 35px;
  display: inline-block;
  background: #27303D;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #27303D;
  float: right;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
}

.input_area {
  max-width: 460px;
  margin-bottom: 25px;
}

.input_area input {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border: none;
  font-family: 'Inter';
  outline: none;
  color: #555;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #27303D;
  padding: 14px 16px;
}

.input_area label {
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #27303D;
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
}

.title_area {
  display: inline-block;
  width: 100%;
  margin-bottom: 65px;
}

.title_area h2 {
  margin: 0;
  font-family: Libre Baskerville;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.title_area h3 {
  margin: 0;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}



.floor_area {
  width: 100%;
  display: inline-block;
}

.floors {
  width: 100%;
  display: inline-block;
}
</style>
