<template>
  <div>

    <div class="title_area">
      <h2>Sun.day Rating</h2>
      <h3>Add an illuminance score to each room.</h3>
    </div>



    <div class="rooms">
      <FloorItem v-for="(floor, index) in floors" :key="index" @deleteFloor="onDeleteFloor" @selectRoom="onSelectRoom"
        :floor="floor" @deleteRoom="onDeleteRoom" :showConditionalContent="showConditionalContent" />
    </div>

    <div class="floor_manager" :class="{ hidden: !showCreateFloor }">
      <div class="input create_floor">
        <input v-model="newFloor" type="text" class="floor_name" placeholder="Select floor" @click="toggleDropdown">
        <ChevronDownIcon class="cursor-pointer" v-if="!newFloor.length" />
        <PlusIcon class="cursor-pointer" @click="addFloorOption" v-else />
      </div>
      <div class="dropdowns" v-if="dropdownVisible">
        <li v-for="(floor, index) in floorOptions" :key="index" @click="selectFloor(floor)">
          {{ floor }}
        </li>
      </div>
    </div>

    <div class="add_others">
      <li class="add_floor" :class="{ 'visible': isFloorSelected }" @click="onAddFloor">
        <span>
          <PlusIcon />
        </span> Add Floor
      </li>
    </div>

    <div class="input_area_button">
      <button @click="back" class="back_button" type="button">Back</button>
      <button @click="onSave" class="save_button" type="submit">Save and Next</button>
    </div>

  </div>
</template>

<script>
import PlusIcon from "@/components/icons/PlusIcon.vue";
import FloorItem from './FloorItem.vue';
import { mapState } from "vuex";
import axios from "axios";
import ChevronDownIcon from "../icons/ChevronDownIcon.vue";
export default {
  data() {
    return {
      newFloor: "",
      isFloorSelected: false,
      dropdownVisible: false,
      showCreateFloor: true,
      floorOptions: ["Ground floor", "1st floor", "2nd floor", "3rd floor","4th floor","5th floor","6th floor","7th floor","8th floor","Attic"],
      floors: [],
      showConditionalContent: false,
      showAddRoomButton: false
    };
  },
  methods: {
    addFloorOption() {
      this.selectFloor(this.newFloor);
      this.floorOptions.unshift(this.newFloor);
      this.newFloor = "";
    },
    back() {
      this.$emit('changeTab', 1);
    },
    onSave() {
      const url = 'https://rest.wedesignwecode.com/?type=update_project';
      const fd = new FormData();

      fd.append("project_id", this.wizard.projectId);
      fd.append("floors", JSON.stringify(this.floors))
      axios.post(url, fd)
        .then(() => {
          this.$store.commit('setFloors', this.floors);
          this.$emit('changeTab', 3);
        })
        .catch(error => {
          console.error(error.response);
        });
    },
    onDeleteFloor(title) {
      this.floors = this.getFloors.filter((e) => e.id !== title);

      if (this.floors.length == 0) {
        this.showCreateFloor = true;
      }
    },
    onDeleteRoom({ roomName, floorTitle }) {
      this.floors = this.getFloors.map((e) => {
        if (e.title === floorTitle) {
          return {
            ...e,
            rooms: e.rooms.filter((a) => a.name !== roomName)
          }
        }
        return e
      })

      if (this.floors.length == 0) {
        this.showCreateFloor = true;
      }
    },
    onSelectRoom({ floor, name, rating }) {
      const floorIndex = this.getFloors.findIndex((e) => e.id == floor);
      this.floors[floorIndex].rooms.push({ name, rating });
    },
    onAddFloor() {
      this.showCreateFloor = true;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectFloor(floor) {
      this.isFloorSelected = true;
      this.dropdownVisible = false;
      this.floors.push({
        id: Math.floor(Math.random() * 1000000),
        title: floor,
        rooms: []
      });
      this.showConditionalContent = true;
      this.showCreateFloor = false;

    },

  },
  computed: {
    ...mapState({
      wizard: (state) => state.wizard
    }),
    getFloors() {
      return this.floors;
    },
  },
  components: {
    FloorItem,
    PlusIcon,
    ChevronDownIcon
  },
  mounted() {
    if(this.$store.state.wizard.floors.length) {
      this.floors = this.$store.state.wizard.floors;
      this.showConditionalContent = true;
    }
  }
};
</script>


<style>
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,700;1,400&family=Work+Sans:wght@400;700&display=swap);

.visible {
  display: block !important;
}

.hidden {
  display: none !important;
}

.room_item {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #BEB694;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.add_others .add_floor {
  display: none;
}

.add_others li {
  display: inline-block;
  width: 100%;
  list-style: none;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid #BEB694;
}

.add_others li:last-child {
  border: none;
}

.add_others li span {
  float: left;
  margin-right: 9px;
}

.add_others li span svg {}


.room_item span {
  float: right;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.4s ease;
}

.room_item span:hover {
  background-color: #EFEFEF;
}

.room_item h2 {
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  margin: 0 0 15px 0;
  display: inline-block;
}

.room_name {
  margin: 25px 0;
}

.dropdowns {
  border-radius: 8px;
  border: 1px solid #27303D;
  width: 300px;
}

.dropdowns li {
  display: inline-block;
  width: 100%;
  padding: 13px 16px;
  list-style: none;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

.create_floor {
  position: relative;
  display: inline-block;
  width: 300px;
}

.create_floor input {
  border-radius: 8px;
  border: 1px solid #27303D;
  background-color: #fff;
  width: 430px;
  padding: 12px 16px;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.create_floor span.arrow {
  position: absolute;
  top: 14px;
  right: 15px;
  float: none;
  width: unset;
  height: unset;
  display: unset;
  background: none !important;
}

.back_button {
  display: inline-block;
  background: #fff;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #27303D;
  border: 1px solid #27303D;
  float: left;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 35px;
}

.save_button {
  margin-top: 35px;
  display: inline-block;
  background: #27303D;
  padding: 14px 18px;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #27303D;
  float: right;
  text-transform: uppercase;
  border-radius: 56px;
  font-weight: 700;
  cursor: pointer;
}

.input_area {
  max-width: 460px;
  margin-bottom: 25px;
}

.input_area input {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  border: none;
  font-family: 'Inter';
  outline: none;
  color: #555;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #27303D;
  padding: 14px 16px;
}

.input_area label {
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #27303D;
  width: 100%;
  display: inline-block;
  margin-bottom: 7px;
}

.title_area {
  display: inline-block;
  width: 100%;
  margin-bottom: 65px;
}

.title_area h2 {
  margin: 0;
  font-family: Libre Baskerville;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.title_area h3 {
  margin: 0;
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}


.warning {
  color: red;
}

.floor_manager {
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 20px;
}

.floor_manager ul {
  margin: 0;
  padding: 0;
}

.floor_manager ul li {
  display: inline-block;
  list-style: none;
}

.floor_manager ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: #333;
  margin-right: 20px;
}

.floor_manager ul li a:hover {
  color: red;
}
</style>

